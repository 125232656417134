import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { MdEmail } from 'react-icons/md';
import { useTheme } from 'emotion-theming';

export default function EditGithub() {
  const theme = useTheme();

  return (
    <a
      href={'mailto:soporteayzer@positive0.com'}
      target='_blank'
      rel='noopener noreferrer'
      css={css`
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-top: 48px;
        color: ${theme.colors.text};
        opacity: 0.8;
        font-size: 14px;
        font-weight: normal;
      `}
    >
      <MdEmail style={{ marginRight: '5px' }} />
      Solicitar ayuda
    </a>
  );
}

EditGithub.propTypes = {
  githubEditUrl: PropTypes.string
};

EditGithub.defaultProps = {
  githubEditUrl: null
};
